<template>
  <tr
    :draggable="canDrag"
    @dragend="onDragEnd"
    @dragstart="onDragStart"
    @dragover="onDragOver"
    @dragleave="onDragLeave"
    @mouseover="onMouseOver"
    @mouseleave="onMouseLeave"
  >
    <td
      :class="[isSelected ? 'ring-2 ring-offset-2 ring-indigo-500' : 'focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500']"
      class="px-6 py-4 cursor-pointer whitespace-nowrap hover:bg-gray-200"
      @dblclick="onSelect"
      @click.prevent.stop="onToggleMultiselect"
    >
      <AssetItemThumb :asset="asset" />
    </td>

    <td
      v-if="showComments"
      class="px-6 py-4 whitespace-nowrap"
    >
      <div
        v-if="asset.comment_count > 0"
        class="text-xs text-gray-500"
      >
        {{ asset.comment_count }}
      </div>
      <div
        v-else
        class="text-xs text-gray-500"
      >
        -
      </div>
    </td>

    <td
      v-if="showType"
      class="px-6 py-4 whitespace-nowrap"
    >
      <span
        class="inline-flex px-2 text-xs font-semibold leading-5 text-blue-800 capitalize bg-blue-100 rounded-full"
      >{{ asset.type }}</span>
    </td>

    <td
      v-if="showModified"
      class="px-6 py-4 whitespace-nowrap"
    >
      <template v-if="asset.updated_at">
        <div class="text-sm text-gray-500">
          {{ asset.updated_at | relative }}
        </div>
      </template>
      <div
        v-if="assetOwner"
        class="text-xs text-gray-500"
      >
        by {{ assetOwner.name }}
      </div>
    </td>

    <td
      v-if="showSize"
      class="px-6 py-4 text-sm text-gray-500 whitespace-nowrap"
    >
      <template>{{ asset.size | filesize }}</template>
    </td>

    <td
      v-if="showOptions"
      class="px-6 py-4 text-sm font-medium text-right whitespace-nowrap"
      @click.stop
    >
      <AssetItemMenu
        :can-share="canShare"
        :can-delete="canDelete"
        :can-preview="canPreview && !isFolder"
        :can-replace="!isFolder"
        :can-move-item-up="canMoveItemUp"
        :parent-folder-id="activeFolder.parent"
        @rename-asset="onRename"
        @delete-asset="onDelete"
        @preview-asset="onPreview"
        @share-asset="onShare"
        @move-asset="onMoveDirect(activeFolder.parent)"
      />
    </td>
  </tr>
  <!-- </div> -->
</template>
<script>
import item from './assets-explorer-collection-item-mixin.js'
const AssetItemThumb = () => import('@components/assets/asset-item-thumb')
const ProfileAvatarThumb = () => import('@components/profile/ProfileAvatarThumb')

export default {
  mixins: [item],

  data() {
    return {
      showOpts: false
    }
  },

  components: {
    AssetItemThumb,
    ProfileAvatarThumb
  },

  mounted() {
    if (this.actionMode === 'update') {
      this.showOpts = true
    }
  },

  methods: {

    onSelect(event) {
      this.$emit('select', { file: this.asset, event: event })
    },
    onMouseOver() {
      this.showOpts = true
    },
    onMouseLeave() {
      if (this.actionMode !== 'update') this.showOpts = false
    }
  },

  computed: {
    justifyClass() {
      let c = 'justify-start'
      if (this.showSelectActionOption) c = 'justify-between'
      return c
    },
    assetOwner: function () {
      if (this.asset.uploaded_by_name !== null && this.asset.uploaded_by_name !== '') {
        return {
          name: this.asset.uploaded_by_name
        }
      }
      return false
    },
    hasThumbnail: function () {
      return (this.asset.thumbnail_tiny !== null)
    },
    rowClass: function () {
      let base = 'list-row-type-' + this.asset.type
      return {
        'active': this.isSelected || this.isFocused || this.isMultiselected,
        'list-row-draggable': this.canDrag,
        base
      }
    },
    thumbnail: function () {
      return this.asset.thumbnail_tiny
    },
    iconClass: function () {
      const type = this.asset.type
      const status = this.asset.status
      let icon = 'icon '
      switch (status) {
        case 'error':
          icon += 'warning circle red'
          break
        case 'pending':
          icon += 'spinner grey loading'
          break
        case 'processing':
          icon += 'spinner blue loading'
          break
        case 'ready':
          icon += 'file  '
          icon += (['image', 'video', 'folder'].includes(type) ? type : '')
          break
        case 'uploading':
          icon += ' spinner orange loading'
          break
        case 'disabled':
          icon += ' red lock'
          break
        default:
          icon += ' outline grey ' + type
          break
      }
      switch (type) {
        case 'folder':
          icon += ' blue '
          break
      }
      return icon
    }
  }
}
</script>
